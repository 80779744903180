import React from 'react';
import ReactDOM from 'react-dom';
import './style/style.scss';
import HeaderMenu from './HeaderMenu';
import App from './App';
import FooterMenu from './FooterMenu';
import * as serviceWorker from './serviceWorker';

//this is the basic structure of the app
ReactDOM.render([
    <HeaderMenu />,
    <App />,
    <FooterMenu />
], document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
