import React from 'react';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function HeaderMenu() {
  return (
    <header>
        <Navbar className="main-nav" bg="black" variant="dark">
            <Navbar.Brand href="/">Chaos</Navbar.Brand>
            <Nav className="mr-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/imprint">Imprint</Nav.Link>
                <Nav.Link href="/marco/ist/der/beste">404 Test</Nav.Link>
            </Nav>
        </Navbar>
    </header>
  );
}

export default HeaderMenu;