import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Imprint() {
  return (
      <Container className="con-spacer">
        <Row>
          <Col xs={12}>
            <div className="imprint">
                <h1>Imprint</h1>
                <h2>Information according to § 5 TMG:</h2>

                <p>Marco Sadowski</p>
                <p>Wehringhauserstrasse 73</p>
                <p>58089 Hagen, Germany</p>

                <h3>Contact:</h3>
                <p>E-mail: marco@keypunk.com</p>

                <p>Should any content or the design of individual pages or parts of this website violate the rights of third parties or statutory provisions or in any other way give rise to problems under competition law, we ask for an appropriate, sufficiently explanatory and fast message without cost note with reference to § 8 Paragraph 4 UWG. We guarantee that the rightfully objected passages or parts of these web pages are removed in appropriate period and/or are adapted to the legal defaults extensively, without the engagement of a legal adviser is necessary from your side. The involvement of a lawyer for the purpose of issuing a warning notice, for which the service provider is liable to pay the costs, does not correspond to the service provider's actual or presumed will and would therefore constitute a violation of § 13 Para. 5 UWG, due to the pursuit of irrelevant objectives as the authoritative motive for the initiation of proceedings, in particular an intention to achieve costs as the actual driving force, as well as a violation of the duty to mitigate damages.</p>
                <p>Costs incurred without prior contact will be fully rejected and may trigger a counterclaim for violation of the aforementioned provisions.</p>

                <h2>Disclaimer of liability</h2>

                <h3>Contents of the online offer</h3>
                <p>The author assumes no liability for the topicality, correctness, completeness or quality of the information provided. Liability claims against the author relating to material or immaterial damage caused by the use or non-use of the information provided or by the use of incorrect or incomplete information are excluded, unless there is evidence of wilful intent or gross negligence on the part of the author.</p>
                <p>All offers are subject to change and non-binding. The author expressly reserves the right to change, supplement or delete parts of the pages or the entire offer without prior notice or to discontinue publication temporarily or permanently.</p>

                <h3>Liability for contents</h3>

                <p>As a service provider we are responsible according to § 7 Abs.1 TMG for our own contents on these pages according to the general laws. According to §§ 8 to 10 TMG, we are not obliged to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information in accordance with general laws remain unaffected by this. However, liability in this respect is only possible from the time of knowledge of a concrete violation of the law. As soon as we become aware of such infringements, we will remove the content immediately.</p>

                <h3>Liability for links</h3>

                <p>Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the contents of the linked pages. The linked pages were checked for possible legal infringements at the time of linking. Illegal contents were not recognisable at the time of linking. A permanent control of the contents of the linked pages is not reasonable without concrete evidence of an infringement. As soon as we become aware of any legal infringements, we will remove such links immediately.</p>

                <h3>Copyright</h3>

                <p>The contents and works on these pages created by the site operators are subject to German copyright law. Duplication, processing, distribution and any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator. Downloads and copies of these pages are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, contents of third parties are marked as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. As soon as we become aware of any infringements, we will remove such content immediately.</p>

                <h3>Legal validity of this disclaimer</h3>

                <p>This disclaimer is to be regarded as part of the internet publication which you were referred from. If sections or individual terms of this statement are not legal or correct, the content or validity of the other parts remain uninfluenced by this fact.</p>
            </div>
          </Col>
        </Row>
      </Container>
  );
}

export default Imprint;