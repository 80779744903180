import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image'
import Button from "react-bootstrap/Button";

function Home() {
  return (
      <Container className="con-spacer homepage">
        <Row>
          <Col className="con-spacer center" xs={12}>
            <Image className="logo con-spacer" src={require('../assets/keypunk-logo-white.png')} rounded />
            <h1>Earn Coins. Get Steam Keys. It's free!</h1>
            <Button href="/steam-auth/index.php?login" variant="dark"><i className="icon-steam login-icon"></i> Login with Steam</Button>

          </Col>
        </Row>
        <Row>
            <Col className="con-spacer" xs={12} lg={8}>
                <h2>KeyPunk? What is KeyPunk?</h2>
                <p>The concept of KeyPunk is simple. You want to grow your Steam Libary without spending a big amount of money? Join Keypunk today and start earning coins by completing tasks. If you've earned enough coins, you can exchange them for Steam Keys. You only need a Steam account to get started!</p>
            </Col>
            <Col className="con-spacer" xs={12} lg={8}>
                <h2>How this is possible?</h2>
                <p>This is possible through advertising. Our site is mostly advertising-financed. But you can also buy Coins to support us. You can also support the development of KeyPunk via Patreon!</p>
            </Col>
            <Col className="con-spacer" xs={12} lg={8}>
                <h2>I'm a Game-Dev! How my game can be a part of KeyPunk</h2>
                <p>Game Developer, Publisher or Authorized Reseller? KeyPunk is the best place for your games! We promote and sell your games. Are you interested or want to know more about it? Great. Let’s talk about more details.</p>
            </Col>
        </Row>
      </Container>
  );
}

export default Home;