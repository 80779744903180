import React from 'react';

import DirectusSDK from "@directus/sdk-js";

import {BrowserRouter as Router, Route, Switch, Link} from "react-router-dom";
import Home from "./sites/Home";
import About from "./sites/About";
import Help from "./sites/Help";
import Gameinfo from "./sites/Gameinfo"
import Imprint from "./sites/Imprint";
import NotFound from "./sites/NotFound";
import Privacy from "./sites/Privacy"


function App() {
  // setTimeout(function() {
  //   //client.logout();
  //   console.log(client.getMe());
  // }, 10000);
  return (
      <Router>
          <Switch>
              <Route exact path='/'>
                  <Home />
              </Route>
              <Route path='/imprint'>
                  <Imprint />
              </Route>
              <Route path='/privacy'>
                  <Privacy />
              </Route>
              <Route path='/game/:gameId'>
                  <Gameinfo />
              </Route>
              <Route path="" component={NotFound} />
          </Switch>
      </Router>
  );
}

export default App;