import React from 'react';




function Gameinfo() {

    var a = this.props;

  return (
      <h1>Lorem ipsum {a}</h1>
  );
}

export default Gameinfo;