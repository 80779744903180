import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function NotFound() {
  return (
      <Container className="con-spacer notfound">
        <Row>
          <Col xs={12}>
            <p className="lenny-404">!!!（╯&#176;□&#176;）╯ミ &lt;404/&gt;</p>
              <h1>Ups!</h1>
            <p>Hey, listen! I do not know how you got here but the content that you search here do not exist.</p>
            <p>Sorry.</p>
          </Col>
        </Row>
      </Container>
  );
}

export default NotFound;