import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function FooterMenu() {
  return (

  <footer className="bg-black">
      <Container className="con-spacer">
          <Row>
              <Col xs={12}>
                  <div className="copyright">KeyPunk is a <a href="https://camefrom.space">camefrom.space</a> project <br /> Made by Marco Sadowski</div>
              </Col>
              <Col xs={12}>
                  <ul>
                      <li><a href="/help">Help</a></li>
                      <li><a href="/privacy">Privacy Policy</a></li>
                      <li><a href="/imprint">Imprint</a></li>
                  </ul>
              </Col>
          </Row>
      </Container>
  </footer>
  );

}

export default FooterMenu;